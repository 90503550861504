<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-input v-model="queryInfo.assetSn" class="input" placeholder="资产码" clearable></el-input>
      <el-button type="primary" @click="search" :loading="loading">查询</el-button>
      <el-button type="primary" @click="importDialogVisible = true">批量处理</el-button>
    </el-row>

    <el-table :data="issueList" border stripe>
      <el-table-column label="操作" align="center" width="80">
        <template slot-scope="scope">
          <el-button v-if="scope.row.finish===false" type="primary" size="mini" @click="showDealIssueDialog(scope.row.id)">处理</el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="assetSn" label="资产码" width="200"></el-table-column>
      <el-table-column align="center" prop="stationName" label="工站" width="120"></el-table-column>
      <el-table-column align="center" prop="issueRemark" label="异常描述" min-width="500"></el-table-column>
      <el-table-column align="center" prop="finish" label="是否完成" width="80" :formatter="isFormatter"></el-table-column>
      <el-table-column align="center" prop="createUserName" label="创建人" width="80"></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间" width="150"></el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
    </div>

    <el-dialog title="作业异常登记" :visible.sync="dialogVisible" :close-on-click-modal="false" width="750px" :before-close="handleClose">
      <el-form v-model="formData" label-width="150px">
        <el-form-item label="资产码：">{{ issue.assetSn }}</el-form-item>
        <el-form-item label="工站：">{{ issue.stationName }}</el-form-item>
        <el-form-item label="异常描述：">{{ issue.issueRemark }}</el-form-item>
        <el-form-item label="处理结果：">
          <el-input v-model="formData.dealRemark" placeholder="请输入处理结果" type="textarea" maxlength="500" rows="10" style="width: 400px" clearable></el-input>
        </el-form-item>
        <el-form-item label="是否完成：">
          <el-switch style="margin-right: 10px" v-model="formData.finish"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="dealIssue" :loading="loadingDeal">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog title="批量处理规则" :visible.sync="importDialogVisible" :close-on-click-modal="false" width="800px" :before-close="importHandleClose">
      <ImportComponent template-url="template/busItemIssueDeal.xlsx" upload-url="busItemIssue/uploadDeal"/>
      <div slot="footer" class="dialog-footer">
        <el-button @click="importHandleClose">关闭</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import ImportComponent from "@/components/ImportComponent";

export default {
  name: "DealIssue",
  components: {ImportComponent},
  data() {
    return {
      queryInfo: {
        assetSn: '',
      },
      formData: {
        finish: false,
        dealRemark: ''
      },
      issue: {},
      total: 0,
      pageSize: 10,
      dialogVisible: false,
      importDialogVisible: false,
      loading: false,
      loadingDeal: false,
      issueList: []
    }
  },
  created() {
    this.search()
  },
  methods: {
    search() {
      this.loading = true
      this.$axios.post('busItemIssue/queryIssuePage', this.queryInfo).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        this.issueList = res.data.records;
        this.pageSize = res.data.size
        this.total = res.data.total
      }, error => {
        this.loading = false;
        return this.$message.error('查询失败，' + error.message);
      });

    },
    showDealIssueDialog(id) {
      this.$axios.post('busItemIssue/getIssue', id).then(response => {
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        this.issue = res.data;
        this.formData.dealRemark = this.issue?.dealRemark
        this.formData.finish = this.issue?.finish
      }, error => {
        this.loading = false;
        return this.$message.error('查询失败，' + error.message);
      });

      this.dialogVisible = true
    },
    dealIssue() {
      if (!this.issue.id) {
        return this.$message.error('id参数未空');
      }

      if (!this.formData.dealRemark) {
        return this.$message.error('处理结果不能为空');
      }

      if (this.formData.finish !== false && this.formData.finish !== true) {
        return this.$message.error('是否完成不能为空');
      }

      this.formData.id = this.issue.id
      this.loadingDeal = true
      this.$axios.post('busItemIssue/dealIssue', this.formData).then(response => {
        this.loadingDeal = false
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        this.$message.success('处理成功')
        this.currentChange(1)
        this.issue = {}
        this.dialogVisible = false

      }, error => {
        this.loadingDeal = false;
        return this.$message.error('查询失败，' + error.message);
      });


    },
    handleClose() {
      this.dialogVisible = false;
    },
    importHandleClose() {
      this.search();
      this.importDialogVisible = false;
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search()
    },
    isFormatter(row, column, cellValue) {
      if (cellValue == true) {
        return '是';
      }
      if (cellValue == false) {
        return '否';
      }
      if (cellValue == null || cellValue == '') {
        return '';
      }
    },
  }
}
</script>

<style scoped>
.input {
  width: 300px;
  margin-right: 10px;
}
</style>